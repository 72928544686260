@import "vendor/vendor";

@import "variables";
@import "scaffolding";

main{
  section.landing{
    height: 700px;
    background: $gradient, resolve('landingBg.jpg') no-repeat;
    @media(min-width:1025px){
      background-attachment: fixed;
    }
    background-size: cover;
    background-position: center;
    position: relative;
    padding-bottom: 69 + 40px;
    @media(max-width:767px){
      height: auto;
      background-image: $gradient;
    }
    img.logo{
      margin-top: 150px;
      margin-bottom: 55px;
      width: 320px;
      @media(max-width:767px){
        width:70%;
        margin-top: 50px;
        margin-bottom: 30px;
      }
    }
    h1{
      margin-bottom: 40px;
      @media(max-width:767px){
        margin-bottom: 30px;
      }
    }
    img.down{
      position: absolute;
      bottom: 40px;
      left:50%;
      transform: translate(-50%);
      cursor: pointer;
      z-index: 2;
    }
    svg{
      position: absolute;
      height: auto;
      bottom: 0;
      left: 0;
      @media(max-width:991px){
        display: none;
      }
    }
  }


  section.benefit{
    background: resolve('graph.svg') no-repeat bottom;
    padding-bottom: 80px;
    @media(max-width:767px){
      padding-bottom: 0px;
      h2{
        margin-bottom: 70px;
      }
    }
    @media (min-width:768px) and (max-width:991px){
      padding-bottom: 50px;
    }
    ul{
      list-style: none;
      margin: 0;
      padding: 0;
      li{
        background: #3B3B3B;
        margin-bottom:20px;
        color: $white;
        border-radius: 6px;
        text-align: left;
        padding: 20px;
        @media(max-width:767px){
          margin-bottom: 40px;
        }
        div.icon{
          background: white;
          width: 65px;
          height: 65px;
          line-height: 60px;
          float: left;
          border-radius: 65px;
          text-align: center;
          vertical-align: middle;
          margin-right: 20px;
          @media(max-width:767px){
            display: block;
            float: none;
            margin: -55px auto 0;
            border: 3px solid $purple-light;
          }
        }
        p{
          display: inline;
          font-size: 22px;
          line-height: 28px;
          vertical-align: middle;
          @media(max-width:767px){
            font-size: 16px;
            line-height: inherit;
            text-align: center;
          }
        }
      }
    }
  }

  section.usp{
    background: resolve('uspBg.jpg') no-repeat top center;
    background-size: cover;
    background-position: center;
    position: relative;
    padding: 0 0 130px 0;
    @media(min-width:1025px){
      background-attachment: fixed;
    }
    @media(max-width:767px){
      padding-bottom: 60px;
    }
    @media (min-width:768px) and (max-width:991px){
      padding-bottom: 80px;
    }
    overflow: auto;
    h2{
      margin-bottom: 40px;
      @media(max-width:767px){
        margin-bottom: 25px;
      }
    }
    div.box{
      background: $white;
      border-radius: 6px;
      padding-bottom: 50px;
      min-height: 365px;
      margin-top: 130px;
      @media(max-width:991px){
        padding-bottom: 20px;
        min-height: inherit;
        margin-top: 20px;
      }
      @media(min-width:768px){
        min-height: 320px;
      }
      div.icon{
        width: 220px;
        height: 220px;
        border-radius: 220px;
        line-height: 220px;
        background: $purple;
        margin: -110px auto 20px;
        display: inline-block;
        @media(max-width:991px){
          width: auto;
          height: auto;
          line-height: inherit;
          padding: 10px;
          margin: 0 auto 10px;
          display: block;
          border-radius: 6px 6px 0 0;
          img{
            height: 60px;
          }
        }
        @media(max-width:767px){
          img{
            height: 40px;
          }
        }
      }
      div.icon.purple-light{
        background: $purple-light;
      }
      div.icon.pink{
        background: $pink;
      }

      h3{
        font-size: 38px;
        line-height: 48px;
        font-weight: normal;
        margin-bottom: 15px;
        @media(max-width:991px){
          font-size: 32px;
          line-height: 42px;
        }
        @media(max-width:767px){
          font-size: 24px;
          line-height: inherit;;
          margin-bottom: 0;
        }
      }

      p{
        font-size: 18px;
        line-height: 23px;
        padding: 0 15px;
        @media(max-width:991px){
          font-size: 16px;
        }
      }
    }
  }

  section.testimonial{
    position: relative;
    @media(max-width:767px){
      br{
        display: block;
      }
    }
    img.trinagle{
      position: absolute;
      top: 0;
      left:50%;
      transform: translate(-50%);
    }
    div.profile{
      border-right: 1px solid white;
      @media(max-width:767px){
        border-right: none;
      }
      margin-right: 10px;
      padding-top: 10px;
      padding-bottom: 10px;
      @media(max-width:767px){
        padding-top: 20px;
      }
      img{
        width: 140px;
        height: 140px;
        margin-bottom: 10px;
      }
      p{
        font-size: 18px;
        font-weight: bold;
        line-height: 18px;
        margin-bottom: 0;
        span{
          font-weight: normal;
          font-size: 14px;
        }
      }
    }
    h3{
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
      font-size: 30px;
      line-height: 40px;
      font-style: oblique;
      text-align: left;
      padding-right: 20px;
      margin-top: 35px;
      @media(max-width:991px){
        font-size: 26px;
        line-height: 36px;
      }
      @media(max-width:767px){
        font-size: 18px;
        line-height: inherit;
        text-align: center;
        padding-right: 0;
        margin-top: 0;
      }
    }
  }

  section.pricing{
    h2{
      margin-bottom: 130px;
      @media(max-width:767px){
        margin-bottom: 60px;
      }
      @media (min-width:768px) and (max-width:991px){
        margin-bottom: 80px;
      }
    }
    .box{
      background-color: #D8D8D8;
      font-weight: bold;
      border-radius: 6px;
      margin-bottom: 20px;
      @media(max-width:767px){
        margin-bottom: 0;
        border-radius: 6px 6px 0 0;
      }
      div.top{
        background-image: resolve("rookie.svg");
        background-repeat: no-repeat;
        background-size: 100%;
        min-height: 265px;
        overflow: auto;
        @media(max-width:991px){
          background-image: none;
          background-color: $pink;
          min-height: inherit;
          border-radius: 6px 6px 0 0;
        }
      }
      h3{
        font-family: 'FrutigerNext LT Bold';
        font-size: 38px;
        line-height: 48px;
        margin-top: 15px;
        margin-bottom: 20px;
        @media(max-width:991px){
          font-size: 32px;
          line-height: 42px;
          margin-top: 10px;
          margin-bottom: 15px;
        }
        @media(max-width:767px){
          font-size: 26px;
          line-height: normal;
          margin-bottom: 10px;
        }
      }
      p.price{
        font-size: 52px;
        font-family: 'FrutigerNext LT Bold';
        span{
          font-size: 16px;
          line-height: normal;
        }
        @media(max-width:767px){
          font-size: 32px;
          line-height: 22px;
        }
      }
      ul{
        list-style: none;
        margin: 0;
        padding: 0;
        li{
          height: 60px;
          font-weight: normal;
          display: flex;
          justify-content:center;
          align-content:center;
          flex-direction:column;
          font-size: 18px;
          @media(max-width:991px){
            font-size: 16px;
          }
          @media(max-width:767px){
            height: auto;
            padding: 5px;
          }
        }
        li.odd{
          background: #E6E6E6;
        }
      }
    }
    .box.rainmaker div.top{
      background-image: resolve("rainmaker.svg");
      margin-top: -60px;
      min-height: 325px;
      @media(max-width:991px){
        background-image: none;
        background-color: $purple;
        min-height: inherit;
        margin-top: -40px;
      }
      @media(max-width:767px){
        margin-top: 0;
      }

      >span{
        text-decoration: underline;
        font-weight: normal;
        margin-top: 10px;
        display: block;
      }
      h3{
        font-size: 42px;
        margin-bottom: 30px;
        @media(max-width:767px){
          font-size: 26px;
          line-height: normal;
          margin-bottom: 10px;
        }
      }
      p.price{
        font-size: 64px;
      }
      @media(min-width:768px) and (max-width:991px){
        h3{
          font-size: 36px;
          margin-bottom: 20px;
        }
        p.price{
          font-size: 56px;
        }
      }
      @media(max-width:767px){
        h3{
          font-size: 26px;
          line-height: normal;
          margin-bottom: 10px;
        }
        p.price{
          font-size: 32px;
          line-height: 22px;
        }
      }
    }
    .box.suits div.top{
      background-image: resolve("suits.svg");
      @media(max-width:991px){
        background-image: none;
        background-color: $purple-light;
      }
    }
    .action{
      margin: 0 0 110px;
      button{
        width: 70%;
        height: 60px;
        border-radius: 6px;
        color: white;
        border: $pink 2px solid;
        background: $pink;
        font-size: 24px;
      }
      @media(max-width:767px){
        margin-bottom: 20px;
        button{
          width: 100%;
          border-radius:0 0 6px 6px;
          font-size: 22px;
        }
      }
    }
    .action.rainmaker button{
      border: $purple 2px solid;
      background: $purple;
    }
    .action.suits button{
      border: $purple-light 2px solid;
      background: $purple-light;
    }
  }

  section.reserve{
    position: relative;
    img.ribbon{
      position: absolute;
      top: 0;
      right: 0;
      @media(max-width:991px){
        width: 200px;
      }
      @media(max-width:767px){
        display: none;
      }
    }
    h3{
      font-family: 'FrutigerNext LT Bold';
      font-size: 28px;
      margin-top: 40px;
      @media(max-width:767px){
        font-size: 20px;
      }
    }
    form{
      font-size: 20px;
      margin-top: 30px;
      .row{
        margin-bottom: 25px;
      }
      @media(max-width:767px){
        font-size: 16px;
      }
    }
    button{
      width: 30%;
      height: 55px;
      border: 2px solid white;
      border-radius: 6px;
    }
    button.active{
      background: white;
      color: $dark;
    }
    div.accounts{
      button[data-account="rainmaker"]{
        margin:0 5%;
      }
    }
    input[type="text"],input[type="email"]{
      height: 55px;
      width: 100%;
      background: none;
      border: 2px solid white;
      border-radius: 6px;
      outline: none;
      padding: 10px 20px;
      @media(max-width:767px){
        padding: 10px;
      }
    }
    .row.action{
      margin-bottom: 20px;
      @media(max-width:767px){
        margin-bottom: 0;
      }
    }
  }

}
