/*!
 * Bootstrap v4.0.0-alpha.2 (http://getbootstrap.com)
 * Copyright 2011-2015 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

// Core variables and mixins
@import "./bootstrap_variables";
@import "bootstrap/scss/mixins";

// Reset and dependencies
@import "bootstrap/scss/normalize";
// @import "bootstrap/scss/print";

// Core CSS
@import "bootstrap/scss/reboot";
@import "bootstrap/scss/type";
// @import "bootstrap/scss/images";
// @import "bootstrap/scss/code";
@import "bootstrap/scss/grid";
// @import "bootstrap/scss/tables";
// @import "bootstrap/scss/forms";
// @import "bootstrap/scss/buttons";

// Components
// @import "bootstrap/scss/animation";
// @import "bootstrap/scss/dropdown";
// @import "bootstrap/scss/button-group";
// @import "bootstrap/scss/input-group";
// @import "bootstrap/scss/custom-forms";
// @import "bootstrap/scss/nav";
// @import "bootstrap/scss/navbar";
// @import "bootstrap/scss/card";
// @import "bootstrap/scss/breadcrumb";
// @import "bootstrap/scss/pagination";
// @import "bootstrap/scss/pager";
// @import "bootstrap/scss/labels";
// @import "bootstrap/scss/jumbotron";
// @import "bootstrap/scss/alert";
// @import "bootstrap/scss/progress";
// @import "bootstrap/scss/media";
// @import "bootstrap/scss/list-group";
// @import "bootstrap/scss/responsive-embed";
// @import "bootstrap/scss/close";

// Components w/ JavaScript
// @import "bootstrap/scss/modal";
// @import "bootstrap/scss/tooltip";
// @import "bootstrap/scss/popover";
// @import "bootstrap/scss/carousel";

// Utility classes
// @import "bootstrap/scss/utilities";
// @import "bootstrap/scss/utilities-background";
// @import "bootstrap/scss/utilities-spacing";
@import "bootstrap/scss/utilities-responsive";

// Overrides
@import "bootstrap-overrides";
