@font-face {
  font-family: 'FrutigerNext LT Regular';
  src: url('../fonts/LT_54361.eot'); /* IE9 Compat Modes */
  src: url('../fonts/LT_54361.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/LT_54361.woff') format('woff'), /* Modern Browsers */
       url('../fonts/LT_54361.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../fonts/LT_54361.svg#a1c1d8086204becf8ac3194da08a1064') format('svg'); /* Legacy iOS */

  font-style:   normal;
  font-weight:  400;
}

@font-face {
  font-family: 'FrutigerNext LT Bold';
  src: url('../fonts/LT_54363.eot'); /* IE9 Compat Modes */
  src: url('../fonts/LT_54363.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../fonts/LT_54363.woff') format('woff'), /* Modern Browsers */
       url('../fonts/LT_54363.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('../fonts/LT_54363.svg#949490a8bcbb53aeec0c9e1e4b8b8e60') format('svg'); /* Legacy iOS */

  font-style:   normal;
  font-weight:  700;
}

body{
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'FrutigerNext LT Regular';
}

@media(max-width:767px){
  br{
    display:none;
  }
}

main{
  color: $dark;
  text-align: center;
  h1,h2{
    font-size: 36px;
    line-height: 48px;
    font-family: 'FrutigerNext LT Bold';
    @media(max-width:767px){
      font-size: 24px;
      line-height: 30px;
    }
  }

  h2{
    margin: 110px 0 80px;
    @media(max-width:991px){
      margin: 70px 0 60px;
    }
    @media(max-width:991px){
      margin: 50px 0 40px;
    }
  }

  p{
    font-size: 24px;
    line-height: 36px;
    @media(max-width:767px){
      font-size: 18px;
      line-height: inherit;
    }
  }

  button{
    background: transparent;
    padding: 0;
    border: none;
  }
  button:focus {outline:0;}
}
.gradient{
  background: $gradient;
  padding: 60px 0;
  h2{
    margin: 0 0 40px 0;
  }
  @media(max-width:767px){
    padding: 50px 0;
    h2{
      margin: 0 0 20px 0;
    }
  }
}
.light{
  color: $white;
  text-shadow: 0px 1px 1px rgba(0,0,0,0.50);
}

::-webkit-input-placeholder { /* WebKit, Blink, Edge */
    color: white;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
   color: white;
   opacity:  1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
   color: white;
   opacity:  1;
}
:-ms-input-placeholder { /* Internet Explorer 10-11 */
   color: white;
}

footer{
  background: $dark;
  color: white;
  line-height: 70px;
  font-size: 14px;
  img.logo{
    width: 100px;
    margin-right: 10px;
  }
  @media(max-width:991px){
    line-height: inherit;
    text-align: center;
    padding:20px 0;
    font-size: 12px;
    img.logo{
      display: block;
      margin: 0 auto 10px auto;
    }
  }
  .right{
    text-align: right;
    @media(max-width:991px){
      text-align: center;
    }
  }
  a{
    color: white !important;
    text-decoration: none !important;
  }
}

.shake{
  animation: shake .3s linear;
}

@-webkit-keyframes shake {
   8%, 41% {
       transform: translateX(-10px);
   }
   25%, 58% {
       transform: translateX(10px);
   }
   75% {
       transform: translateX(-5px);
   }
   92% {
       transform: translateX(5px);
   }
   0%, 100% {
       transform: translateX(0);
   }
}


body.terms{
  padding: 100px;
}
